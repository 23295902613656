@mixin ImagesTable {
  .images-table {
    .release-col {
      @include breakpoint-widths(0.25, 0.25, 0.2, 0.2, 0.2, true);
    }

    .arch-col {
      @include breakpoint-widths(0.25, 0.2, 0.15, 0.15, 0.1, true);
    }

    .size-col {
      @include breakpoint-widths(0, 0, 0, 0, 0.1, true);
    }

    .status-col {
      @include breakpoint-widths(0.25, 0.45, 0.55, 0.55, 0.5, true);
    }

    .actions-col {
      @include breakpoint-widths(0.25, 0.1, 0.1, 0.1, 0.1, true)
    }
  }
}
