@mixin DebounceSearchBox {
  .debounce-search-box {
    position: relative;
  }

  .debounce-search-box__spinner-container {
    // Use Vanilla's calculation for the the height of the search box
    height: calc(
      #{2 * $spv-nudge + map-get($line-heights, default-text)} - #{2 * $bar-thickness}
    );
    margin: $bar-thickness 0;
    position: absolute;
    right: 3rem;
    top: 0;

    &.nudge-left {
      right: 5rem;
    }
  }
}
