@use "sass:math";

// Import settings
@import "./settings";

// Import and include Vanilla classes/placeholders needed for custom styling.
// NOTE: If you add another mixin here be sure to check every instance of that
// component as it alters the class ordering in Vanilla and may cause
// regressions.
@import "vanilla-framework";
@include vanilla;
@include vf-p-icon-back-to-top;
@include vf-p-icon-begin-downloading;
@include vf-p-icon-change-version;
@include vf-p-icon-edit;
@include vf-p-icon-inspector-debug;
@include vf-p-icon-lock-locked-active;
@include vf-p-icon-power-off;
@include vf-p-icon-restart;
@include vf-p-icon-settings;
@include vf-p-icon-status-waiting;
@include vf-p-icon-success-grey;
@include vf-p-icon-task-outstanding;
@include vf-p-icon-unit-running;

// Import MAAS overrides of Vanilla patterns
@import "./vanilla-overrides";

// Import MAAS global mixins
@import "./breakpoint-widths";
@import "./truncated-border";

// Import and include MAAS global styles
@import "./patterns_buttons";
@import "./patterns_contextual-menu";
@import "./patterns_double-row";
@import "./patterns_footer";
@import "./patterns_forms";
@import "./patterns_grids";
@import "./patterns_icons";
@import "./patterns_links";
@import "./patterns_list-trees";
@import "./patterns_navigation";
@import "./patterns_status-bar";
@import "./patterns_switches";
@import "./patterns_table-actions";
@import "./patterns_tables";
@import "./patterns_tabs";
@import "./patterns_typography";
@import "./pseudo-border";
@import "./utilities";
@include maas-buttons;
@include maas-contextual-menu;
@include maas-double-row;
@include maas-footer;
@include maas-forms;
@include maas-grids;
@include maas-icons;
@include maas-links;
@include maas-list-trees;
@include maas-navigation;
@include maas-status-bar;
@include maas-switches;
@include maas-table-actions;
@include maas-tables;
@include maas-tabs;
@include maas-typography;
@include maas-utilities;

// Import and include MAAS component styles
// base
@import "~app/base/components/ActionBar";
@import "~app/base/components/CertificateDownload";
@import "~app/base/components/CertificateMetadata";
@import "~app/base/components/ColumnToggle";
@import "~app/base/components/DebounceSearchBox";
@import "~app/base/components/DHCPTable";
@import "~app/base/components/DoughnutChart";
@import "~app/base/components/FilterAccordion";
@import "~app/base/components/FormCard";
@import "~app/base/components/FormikFormButtons";
@import "~app/base/components/Header";
@import "~app/base/components/LabelledList";
@import "~app/base/components/Login";
@import "~app/base/components/MachineSelectTable";
@import "~app/base/components/Meter";
@import "~app/base/components/node/HardwareCard";
@import "~app/base/components/node/networking/NetworkTable";
@import "~app/base/components/node/NodeDevices";
@import "~app/base/components/node/NodeLogs/DownloadMenu";
@import "~app/base/components/node/NodeLogs/EventLogs/EventLogsTable";
@import "~app/base/components/node/NodeTestsTable";
@import "~app/base/components/node/OverviewCard";
@import "~app/base/components/NodeName";
@import "~app/base/components/NodeSummaryNetworkCard";
@import "~app/base/components/NodeSummaryNetworkCard/NetworkCardTable";
@import "~app/base/components/NotificationGroup";
@import "~app/base/components/Placeholder";
@import "~app/base/components/Popover";
@import "~app/base/components/MainContentSection";
@import "~app/base/components/SectionHeader";
@import "~app/base/components/SelectButton";
@import "~app/base/components/SSHKeyList";
@import "~app/base/components/Stepper";
@import "~app/base/components/TableMenu";
@import "~app/base/components/TagSelector";
@include ActionBar;
@include CertificateDownload;
@include CertificateMetadata;
@include ColumnToggle;
@include DebounceSearchBox;
@include DHCPTable;
@include DoughnutChart;
@include DownloadMenu;
@include EventLogsTable;
@include FilterAccordion;
@include FormCard;
@include FormikFormButtons;
@include HardwareCard;
@include Header;
@include LabelledList;
@include Login;
@include MachineSelectTable;
@include Meter;
@include NetworkCardTable;
@include NetworkTable;
@include NodeDevices;
@include NodeName;
@include NodeSummaryNetworkCard;
@include NodeTestsTable;
@include NotificationGroup;
@include OverviewCard;
@include Placeholder;
@include Popover;
@include MainContentSection;
@include SectionHeader;
@include SelectButton;
@include SSHKeyList;
@include Stepper;
@include TableMenu;
@include TagSelector;

// controllers
@import "~app/controllers/views/ControllerList/ControllerListTable";
@import "~app/controllers/views/ControllerDetails/ControllerSummary";
@include ControllerListTable;
@include ControllerSummary;

// devices
@import "~app/devices/components/DeviceHeaderForms/AddDeviceForm/AddDeviceInterfaces";
@import "~app/devices/views/DeviceDetails/DeviceNetwork/DeviceNetworkTable";
@import "~app/devices/views/DeviceDetails/DeviceSummary";
@import "~app/devices/views/DeviceList/DeviceListTable";
@include AddDeviceInterfaces;
@include DeviceListTable;
@include DeviceNetworkTable;
@include DeviceSummary;

// images
@import "~app/images/components/ImagesTable";
@include ImagesTable;

// intro
@import "~app/intro/views/MaasIntro";
@include MaasIntro;

// kvm
@import "~app/kvm/components/CoreResources";
@import "~app/kvm/components/CPUColumn/CPUPopover";
@import "~app/kvm/components/KVMDetailsHeader";
@import "~app/base/components/DhcpFormFields/MachineSelect";
@import "~app/kvm/components/KVMHeaderForms/ComposeForm/InterfacesTable";
@import "~app/kvm/components/KVMHeaderForms/ComposeForm/InterfacesTable/SubnetSelect";
@import "~app/kvm/components/KVMHeaderForms/ComposeForm/StorageTable";
@import "~app/kvm/components/KVMHeaderForms/ComposeForm/StorageTable/PoolSelect";
@import "~app/kvm/components/KVMResourcesCard";
@import "~app/kvm/components/KVMStorageCards";
@import "~app/kvm/components/LXDHostToolbar";
@import "~app/kvm/components/LXDHostVMs/NumaResources";
@import "~app/kvm/components/LXDHostVMs/NumaResources/NumaResourcesCard";
@import "~app/kvm/components/LXDVMsSummaryCard";
@import "~app/kvm/components/LXDVMsTable/VMsTable";
@import "~app/kvm/components/RAMColumn/RAMPopover";
@import "~app/kvm/components/RamResources";
@import "~app/kvm/components/StorageColumn/StoragePopover";
@import "~app/kvm/components/StorageResources";
@import "~app/kvm/components/StorageResources/StorageCards";
@import "~app/kvm/components/VfResources";
@import "~app/kvm/components/VmResources";
@import "~app/kvm/components/SettingsBackLink";
@import "~app/kvm/views/KVMList/LxdTable/LxdKVMHostTable";
@import "~app/kvm/views/KVMList/VirshTable";
@import "~app/kvm/views/LXDClusterDetails/LXDClusterHosts/LXDClusterHostsTable";
@import "~app/kvm/views/LXDClusterDetails/LXDClusterSummaryCard";
@import "~app/controllers/views/ControllerDetails/ControllerVLANs/ControllerVLANsTable";
@include ControllerVLANsTable;
@include CoreResources;
@include CPUPopover;
@include KVMComposeInterfacesTable;
@include KVMComposeStorageTable;
@include KVMDetailsHeader;
@include KVMPoolSelect;
@include KVMResourcesCard;
@include KVMStorageCards;
@include KVMSubnetSelect;
@include LXDClusterHostsTable;
@include LXDClusterSummaryCard;
@include LXDHostToolbar;
@include LxdKVMHostTable;
@include LXDVMsSummaryCard;
@include MachineSelect;
@include NumaResources;
@include NumaResourcesCard;
@include RAMPopover;
@include RamResources;
@include StorageCards;
@include StoragePopover;
@include StorageResources;
@include VfResources;
@include VirshTable;
@include VmResources;
@include SettingsBackLink;
@include VMsTable;

// Dashboard
@import "~app/dashboard/views/DiscoveriesList";
@include DiscoveriesList;

// machines
@import "~app/machines/components/MachineHeaderForms/MachineActionFormWrapper/CloneForm/CloneFormFields";
@import "~app/machines/components/MachineHeaderForms/MachineActionFormWrapper/CloneForm/CloneFormFields/SourceMachineSelect";
@import "~app/machines/components/MachineHeaderForms/MachineActionFormWrapper/CloneForm/CloneResults";
@import "~app/machines/components/MachineHeaderForms/MachineActionFormWrapper/MarkBrokenForm/MarkBrokenFormFields";
@import "~app/machines/components/MachineHeaderForms/MachineActionFormWrapper/TagForm";
@import "~app/machines/views/MachineDetails/MachineSummary";
@import "~app/machines/views/MachineDetails/MachineSummary/NumaCard";
@import "~app/machines/views/MachineList";
@include CloneFormFields;
@include CloneResults;
@include MachineList;
@include MachineSummary;
@include MarkBrokenFormFields;
@include NumaCard;
@include SourceMachineSelect;
@include TagForm;

// preferences
@import "~app/preferences/views/APIKeys/APIKeyList";
@import "~app/preferences/views/SSLKeys/AddSSLKey";
@import "~app/preferences/views/SSLKeys/SSLKeyList";
@include APIKeyList;
@include AddSSLKey;
@include SSLKeyList;

// settings
@import "~app/settings/components/SettingsTable";
@import "~app/settings/views/Configuration/GeneralForm";
@import "~app/settings/views/Configuration/GeneralForm/ThemedRadioButton";
@import "~app/settings/views/Configuration/Security/TLSEnabled/TLSEnabledFields";
@import "~app/settings/views/Dhcp/DhcpList";
@import "~app/settings/views/LicenseKeys/LicenseKeyList";
@import "~app/settings/views/Repositories/RepositoriesList";
@import "~app/settings/views/Repositories/RepositoryFormFields";
@import "~app/settings/views/Scripts/ScriptsList";
@import "~app/settings/views/Scripts/ScriptsUpload";
@import "~app/settings/views/Users/UsersList";
@include SettingsTable;
@include DhcpList;
@include LicenseKeyList;
@include RepositoriesList;
@include RepositoryFormFields;
@include ScriptsList;
@include ScriptsUpload;
@include TLSEnabledFields;
@include UsersList;
@include GeneralForm;
@include ThemedRadioButton;

// subnets
@import "~app/subnets/components/ReservedRanges";
@import "~app/subnets/views/FabricDetails/FabricVLANs";
@import "~app/subnets/views/SubnetDetails/StaticRoutes";
@import "~app/subnets/views/SubnetDetails/SubnetDetailsHeader/SubnetActionForms/components/EditBootArchitectures/BootArchitecturesTable";
@import "~app/subnets/views/SubnetDetails/SubnetUsedIPs";
@import "~app/subnets/views/SubnetsList";
@import "~app/subnets/views/VLANDetails/VLANSubnets";
@include BootArchitecturesTable;
@include FabricVLANs;
@include ReservedRanges;
@include StaticRoutes;
@include SubnetsList;
@include UsedIpTable;
@include VLANSubnets;

// tags
@import "~app/tags/components/TagsHeader/DeleteTagForm/DeleteTagFormWarnings/_index.scss";
@include DeleteTagFormWarnings;

#maas-ui {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
