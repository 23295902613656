@mixin CloneFormFields {
  .clone-form-fields {
    @extend %base-grid;
    grid:
      [row1-start] "source-label clone-label" min-content [row1-end]
      [row2-start] "source-select clone-tables" min-content [row2-end]
      / 1fr 2fr;
    margin-bottom: $spv--x-large;

    .source-label {
      grid-area: source-label;
    }

    .source-select {
      grid-area: source-select;
    }

    .clone-label {
      grid-area: clone-label;
    }

    .clone-tables {
      grid-area: clone-tables;
    }

    @media only screen and (max-width: $breakpoint-x-large) {
      grid:
        [row1-start] "source-label empty" min-content [row1-end]
        [row2-start] "source-select empty" min-content [row2-end]
        [row3-start] "clone-label clone-label" min-content [row3-end]
        [row4-start] "clone-tables clone-tables" min-content [row4-end]
        / 1fr 1fr;
      column-gap: 0;

      .source-select {
        margin-bottom: $spv--x-large;
      }
    }

    @media only screen and (max-width: $breakpoint-navigation-threshold) {
      grid:
        [row1-start] "source-label" min-content [row1-end]
        [row2-start] "source-select" min-content [row2-end]
        [row3-start] "clone-label" min-content [row3-end]
        [row4-start] "clone-tables" min-content [row4-end]
        / 100%;
      column-gap: 0;

      .source-select {
        margin-bottom: $spv--x-large;
      }
    }
  }

  .clone-tables {
    @extend %vf-is-bordered;
    @extend %vf-has-round-corners;
    display: flex;

    @media only screen and (max-width: $breakpoint-navigation-threshold) {
      flex-direction: column;
    }
  }

  .clone-table-card {
    padding: $spv--medium $sph--large 0 $sph--large;

    &:not(:last-of-type) {
      border-right: $border;

      @media only screen and (max-width: $breakpoint-navigation-threshold) {
        border-bottom: $border;
        border-right: 0;
      }
    }
  }

  .clone-table-container {
    max-height: 22rem;
    min-height: 8rem;
    overflow: auto;
  }

  %clone-table {
    th,
    td {
      padding-left: $sph--x-small;
      padding-right: $sph--x-small;
    }

    th:first-child,
    td:first-child {
      padding-left: 0;
    }

    th:last-child,
    td:last-child {
      padding-right: 0;
    }

    &.not-selected {
      opacity: 0.5;
    }
  }

  .clone-table--network {
    @extend %clone-table;

    .name-col {
      width: 27%;
    }

    .fabric-col {
      width: 33%;
    }

    .type-col {
      width: 40%;
    }

    .dhcp-col {
      width: 4.5rem;
    }
  }

  .clone-table--storage {
    @extend %clone-table;

    .name-col {
      width: 33%;
    }

    .model-col {
      width: 34%;
    }

    .type-col {
      width: 33%;
    }

    .size-col {
      width: 5.5rem;
    }

    .available-col {
      width: 4.5rem;
    }
  }
}
