@mixin SourceMachineSelect {
  .source-machine-select {
    @extend %vf-is-bordered;
    @extend %vf-has-round-corners;
    padding: $spv--medium $sph--large 0 $sph--large;
  }

  .source-machine-select__table {
    max-height: 22rem;
    overflow: auto;
  }
}
