@mixin maas-status-bar {
  .p-status-bar {
    background-color: $colors--dark-theme--background-default;
    bottom: 0;
    left: 0;
    padding: $spv--small 0;
    position: sticky;
    right: 0;
    z-index: 1;
  }

  .p-status-bar__row {
    @extend %fixed-width-container;
    color: $color-x-light;
    column-gap: $sph--small;
    flex-direction: column;

    @media only screen and (min-width: $breakpoint-large) {
      flex-direction: row;
    }
  }

  .p-status-bar__secondary {
    @media only screen and (min-width: $breakpoint-large) {
      justify-content: flex-end;
    }
  }
}
